import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text, Box } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Note variant="danger" mdxType="Note">
  <Text sx={{
        display: 'block',
        fontWeight: 'bold',
        mb: 2
      }} mdxType="Text">⚠️ Usage Warning ⚠️</Text>
  <Text mdxType="Text">
    Tooltips as a UI pattern should be the last resort for conveying information as they are hidden by default and often
    with zero or little visual indicator of its existence. See{' '}
    <Link href="#alternatives-to-tooltips" mdxType="Link">tooltip alternatives</Link> before using this component.
  </Text>
    </Note>
    <h2>{`Usage`}</h2>
    <img width="960" role="presentation" src="https://github.com/primer/primitives/assets/18661030/172aa8f6-2273-4719-bb63-8dd678ffcbc9" alt="Three examples of tooltips on interactive elements" />
    <ul>
      <li parentName="ul">{`Reserve tooltips to visually surface the label for icon buttons.`}</li>
      <li parentName="ul">{`Keep the tooltip text minimal.`}</li>
      <li parentName="ul"><strong parentName="li">{`Never`}</strong>{` include tooltips on non-interactive components (`}<inlineCode parentName="li">{`div`}</inlineCode>{`, `}<inlineCode parentName="li">{`span`}</inlineCode>{`, `}<inlineCode parentName="li">{`p`}</inlineCode>{`) because it will not be accessible for keyboard or screen reader users.`}</li>
    </ul>
    <h3>{`Best practices`}</h3>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="456" alt="Icon buttons with tooltips for edit, favorite, and sponsor" src="https://github.com/primer/primitives/assets/18661030/e9f86af8-3792-4794-af68-ad0efccd8bc8" />
    <Caption mdxType="Caption">Use a tooltip on an icon button to help convey the purpose of the action</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="456" alt="Non-interactive text element activating a long and wordy tooltip on hover" src="https://github.com/primer/primitives/assets/18661030/1d8d86a2-8ee7-40d2-8934-c3d36f34d9b3" />
    <Caption mdxType="Caption">Don't use tooltips on non-interactive elements</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Options`}</h2>
    <h3>{`Label`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="icon button with tooltip as a label" src="https://github.com/primer/design/assets/18661030/b06d4fc2-d4e6-482d-a9b9-05eb866ce3e1" />
  <Box as="p" mt="0" mdxType="Box">
    Icon buttons have a visually hidden label utilizing `aria-label`. To instead display that label in a tooltip, the
    `label` option should be used. The `label` serves as the accessible name for the control utilitizing
    `aria-labelledby` under the hood.
  </Box>
    </Box>
    <h3>{`Description`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`description`}</inlineCode>{` is intended to supplement the control's text label by utilitizing `}<inlineCode parentName="p">{`aria-describedby`}</inlineCode>{` under the hood, and will be announced to a screen reader in addition to the label. Keep the description short and concise.`}</p>
    <h4>{`Icon button with visually hidden label`}</h4>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="Icon button with tooltip as a description" src="https://github.com/primer/design/assets/18661030/2d0cd085-2454-406e-8c99-bc8bd3dd20db" />
  <Box as="p" mt="0" mdxType="Box">
    Icon buttons have a visually hidden label utilizing `aria-label`. To provide context in addition to the visually
    hidden label, use the `description` option.
  </Box>
    </Box>
    <h4>{`Button with visible label`}</h4>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="Button labeled Notifications with tooltip as a description" src="https://github.com/primer/design/assets/18661030/a85cc241-c194-498d-b6ad-8e973fd18aca" />
  <Box as="p" mt="0" mdxType="Box">
    For buttons that have a visible label, the `description` option can also be used to provide additional context.
  </Box>
    </Box>
    <h3>{`Position`}</h3>
    <p>{`By default, the Tooltip component will position itself below the interactive control in a centered alignment. The position is dependant on the space available within the viewport, and it will never break outside of it. It may appear to the top, bottom, left, or right of the interactive control.`}</p>
    <img width="960" role="presentation" src="https://github.com/primer/design/assets/18661030/c620d282-1efd-4556-9d9c-770c37359fc9" alt="tooltips rendered in all possible positions surrounding a control" />
    <h2>{`Alternatives to tooltips`}</h2>
    <p>{`Before adding a tooltip, please consider: Is this information essential and necessary? Can the UI be made clearer? Can the information be shown on the page by default? See Tooltip alternatives for more accessible alternatives.`}</p>
    <p>{`We use aria-label for tooltip contents. However, aria-label replaces the text content of an element in screen readers, so only use Tooltip on elements with no existing text content.`}</p>
    <p>{`A tooltip may only be used on an element that is interactive such as a button or a link. Even if an element is focusable, a tooltip may only be used if the element does something when it's clicked.`}</p>
    <h3>{`1. Persist the content`}</h3>
    <p>{`If possible, persist the content so it's always available rather than using a tooltip, which hides content by default. This ensures that the content is always discovered and surfaced to users regardless of device.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="456" alt="Information about how many discussions can be pinned added to the confirmation dialog" src="https://github.com/primer/primitives/assets/18661030/e66db2af-723c-4cfa-8d97-47a42f17047d" />
    <Caption mdxType="Caption">Find an appropriate permanent place to display additional information.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="456" alt="Information about how many discussions can be pinned within tooltip on non-interactive element" src="https://github.com/primer/primitives/assets/18661030/283a5677-ea86-40de-9a84-6751a9b531f8" />
    <Caption mdxType="Caption">Don't use a tooltip to add additional helpful information</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`2. Don't duplicate content`}</h3>
    <p>{`If the tooltip duplicates content that is already available on the page, remove it.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="456" alt="Pointer hovering over a link with no tooltip" src="https://github.com/primer/primitives/assets/18661030/d62b048f-1095-41d8-8bfb-fef204ba50dc" />
    <Caption mdxType="Caption">Remove any duplicate tooltip text.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="456" alt="Screenshot of tooltip with GitHub username, @inkblotty, on a static span that says @inkblotty" src="https://github.com/primer/primitives/assets/18661030/78046a69-57f2-4fcd-9f17-fc06576d704f" />
    <Caption mdxType="Caption">Don't set tooltips that duplicate the trigger element content.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`3. Use a modal`}</h3>
    <p>{`Consider using a modal, which is accessible for mobile users and allows you to structure content that may otherwise be crammed into a tooltip.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="456" alt="Screenshot of GitHub Actions page with a modal that conveys workflow file information about a runner" src="https://user-images.githubusercontent.com/40274682/160210527-2319e408-d433-407f-aac2-66eaf9e63246.png" />
    <Caption mdxType="Caption">Do use a pattern that is accessible to a larger number of users such as a modal</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="456" alt="Screenshot of GitHub Actions page using a tooltip on a static element to convey long workflow file information" src="https://github.com/primer/primitives/assets/18661030/f1107770-cf3b-4bc1-9f75-a766833c67e0" />
    <Caption mdxType="Caption">Don't place long content inside a tooltip</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`4. Use a summary disclosure`}</h3>
    <p>{`Consider using a summary disclosure that is accessible for mobile users and also offers the ability to show or hide content.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="800" alt="Screenshot of GitHub pricing page that uses a summary disclosure to hide and show additional information on each point" src="https://user-images.githubusercontent.com/40274682/160211684-6c102dd6-4039-4c7b-8c52-eeeb71a27db5.png" />
    <Caption mdxType="Caption">Do use a pattern that is accessible to a larger number of users such as a summary disclosure</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="800" alt="Screenshot of GitHub pricing page using a tooltip to hide and show long descriptions on each point" src="https://github.com/primer/primitives/assets/18661030/8efbc68b-7fa9-409d-a020-2e5d75337005" />
    <Caption mdxType="Caption">Don't use tooltips on non-interactive elements</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Other`}</h3>
    <p>{`If you are unsure which alternative is more suited to your scenario and need help, consult a designer or the GitHub Accessibility team (if you are GitHub staff) for advice.`}</p>
    <h2>{`Additional resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://heydonworks.com/article/your-tooltips-are-bogus/"
        }}>{`Your tooltips are bogus`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/github/erblint-github/blob/main/docs/rules/accessibility/no-aria-label-misuse-counter.md#no-aria-label-misuse-counter"
        }}>{`erb lint rule: NoAriaLabelMisuseCounter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/primer/view_components/blob/main/lib/primer/view_components/linters/tooltipped_migration.rb"
        }}>{`Rails tooltip linter`}</a></li>
    </ul>
    <h3>{`Known accessibility issues`}</h3>
    <AccessibilityLink label="tooltip" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      